/* Used in the root of index.js, this file is designed to load AWS configuration from aws-exports file
 * and override the client ID to the one provided in the URL
 */

import Amplify from 'aws-amplify';
import devopsConfig from './aws-exports-poc';
import nonprodConfig from './aws-exports-non-prod';
import prodConfig from './aws-exports-oscar-prod';
import pocConfig from './aws-exports-poc';

import logger from '@/utils/Logger';

import _ from 'lodash';

function AuthConfigure() {
    let configFile;

    switch (process.env.REACT_APP_CONFIG_ENV) {
        case 'local':
        case 'poc':
            configFile = _.cloneDeep(pocConfig);
            break;
        case 'devops':
            configFile = _.cloneDeep(devopsConfig);
            break;
        case 'oscar-non-prod':
            configFile = _.cloneDeep(nonprodConfig);
            break;
        case 'oscar-prod':
            configFile = _.cloneDeep(prodConfig);
            break;
        default:
            configFile = _.cloneDeep(pocConfig);
            break;
    }

    logger.debug('Configured using env: ', process.env.REACT_APP_CONFIG_ENV, configFile);

    const currentUrlParams = new URLSearchParams(window.location.search.toString());

    if (currentUrlParams.has('client_id')) {
        configFile.aws_user_pools_web_client_id = currentUrlParams.get('client_id')
    }

    Amplify.configure(configFile);

    return null;
}

export default AuthConfigure;