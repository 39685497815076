import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import validator from 'validator';
import { Auth } from 'aws-amplify';

import TextInput from '@/components/TextInput';
import ErrorContainer from '@/components/ErrorContainer';

import StepSubTitle from './StepSubTitle';
import StepTitle from './StepTitle';
import Button from '@/components/Button';

function StepEmail({ onNext }) {
    const [email, setEmail] = useState('');
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const nav = useNavigate();

    useEffect(() => {
        setHasError(false);
        setErrorMessage('');

        if (!checkAllowNext()) {
            setHasError(true);
            setErrorMessage('Please enter a valid email address')
        }
    }, [email])

    const handleNext = async () => {
        if (!checkAllowNext()) {
            return;
        }

        try {
            await Auth.signIn(email, 'x');
        }
        catch (ex) {
            if (ex.code === 'UserNotFoundException') {
                return onNext(email);
            }
        }

        setHasError(true);
        setErrorMessage('An account already exists with this email address.');
    }

    const checkAllowNext = () => {
        // TODO: existing user
        return validator.isEmail(email);
    }
    const handleBack = () => {
        nav(-1);
    }

    return <div>
        <StepTitle>Step 2 of 5 Email</StepTitle>
        <div className={'text-block'}>
            <StepSubTitle>Enter an Email</StepSubTitle>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation
            </p>
            <TextInput label={'Email Address'} onChange={(val) => setEmail(val)} />
            {hasError && <ErrorContainer message={errorMessage} />}
        </div>

        <Button type={'secondary'} onClick={handleBack}>Back</Button>
        <Button disabled={!checkAllowNext()} onClick={handleNext}>Next</Button>
    </div>
}

export default StepEmail