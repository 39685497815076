import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

import { Link } from 'react-router-dom';
import TextInput from '@/components/TextInput';
import CheckboxInput from '@/components/CheckBoxInput';
import handleIdpLogin from '@/handleIdpLogin';

import Warning from '@/assets/warning.svg';
import eccLoading from '@/assets/ecc-loading.gif';


import { getProviderFile } from '@/utils/getProviderFile';

import { getCookieData, saveCookieData } from '@/utils/cookieData'
import { setAuthState, setCurrentMFAPrompt, selectAuthState, setPreferredMFA, setProfileComplete, selectBrandingName } from '@/stores/authSlice';
import { useSelector, useDispatch } from 'react-redux'
import PasswordInput from '@/components/PasswordInput';

import { getUrlParams } from '@/utils/getUrlParams';

import Modal from 'react-bootstrap/Modal';
import Button from '@/components/Button';
import ExpiredModal from './ExpiredModal';
import ErrorBox from '@/components/ErrorBox';

const CODE_TIMEOUT = 600000; // in milliseconds = 10 mins. Also listed in IdentityBrokerAuthorize lambda

function SignIn({ onResetPassword }) {
    const dispatch = useDispatch();

    const [showExpiredModal, setShowExpiredModal] = useState(false);

    const [username, setUsername] = useState('');
    const [rememberUsername, setRememberUsername] = useState(true);
    const [step, setStep] = useState('username');
    const [providerName, setProviderName] = useState('');
    const [isBusy, setIsBusy] = useState(false);

    const [password, setPassword] = useState('');
    const [mfaCode, setMfaCode] = useState('');

    const [authResult, setAuthResult] = useState();

    const authState = useSelector(selectAuthState);
    const brandingName = useSelector(selectBrandingName);

    useEffect(() => {
        const cookieData = getCookieData();
        console.log(cookieData)
        setRememberUsername(cookieData.remember_username);
        setUsername(cookieData.username);
    }, [])

    /**
     * 
     */
    const updateCookieData = () => {
        const cookieData = {
            remember_username: rememberUsername
        }

        cookieData.username = rememberUsername ? username : '';

        saveCookieData(cookieData);
    }

    const doLogin = async () => {
        if (authState === 'sms_mfa') {
            const mfaResult = await Auth.confirmSignIn(authResult, mfaCode);
            return dispatch(setAuthState('logged_in'))
        }

        //http://localhost:3000/?client_id=61s317onamh95em4veq65spk1n&redirect_uri=https://test.business.foodtrader.eccnow.com.au/navpage.do&authorization_code=737c5dcb-5a26-43f6-92cf-f8b56fc7002f&source_portal=test.business.foodtrader.eccnow.com.au&state=SNC730d58442a875d6b2189f18e50c24172

        try {
            dispatch(setAuthState('logging_in'))
            updateCookieData();

            const signinResult = await Auth.signIn(username, password);

            setAuthResult(signinResult)
            dispatch(setPreferredMFA(signinResult.preferredMFA));

            if (signinResult.signInUserSession) {
                dispatch(setCurrentMFAPrompt(signinResult.attributes['custom:mfa_prompt']));
                dispatch(setProfileComplete(signinResult.attributes['custom:profile_complete']))
                return dispatch(setAuthState('logged_in'))
            }
            else if (signinResult.challengeName === 'SMS_MFA') {
                dispatch(setAuthState('sms_mfa'));
            }
        }
        catch (ex) {
            if (ex.code === 'UserNotConfirmedException') {
                dispatch(setAuthState('unconfirmed'));
            }
            else {
                dispatch(setAuthState('incorrect'));
            }
        }
    }

    const doFederatedLogin = async (providerName) => {
        updateCookieData();
        handleIdpLogin(providerName);
    }

    const moveToSecondStep = async () => {
        setIsBusy(true);
        updateCookieData();
        const providerInfo = await getProviderFile(username);

        console.log('providerInfo', providerInfo)

        if (!providerInfo.type) {
            console.error('Error loading provider file!');
            return;
        }

        if (providerInfo.type === 'local') {
            setIsBusy(false);
            return setStep('password');
        }

        setStep('federated');
        setProviderName(providerInfo.idp.label);
        setTimeout(() => doFederatedLogin(providerInfo.idp.identityProviderCode), 2000);
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowExpiredModal(true);
        }, CODE_TIMEOUT);

        return () => clearTimeout(timeout);
    }, [])


    const handleClose = () => {
        setShowExpiredModal(false);

        const urlParams = getUrlParams();
        window.location = 'https://' + urlParams.source_portal;
    }

    return <div>
        <div className={'border rounded-md'}>
            <div className={'font-semibold text-2xl text-center mt-14 mb-8'}>Sign in to {brandingName}</div>

            {authState === 'incorrect' && <div className={'p-2'}><ErrorBox icon={<img src={Warning} alt={'Warning Icon'} />}>The email address or password you have entered is incorrect.</ErrorBox></div>}
            {authState === 'unconfirmed' && <div className={'p-2'}><ErrorBox icon={<img src={Warning} alt={'Warning Icon'} />}>The email address has not been confirmed. Resend confirmation email.</ErrorBox></div>}

            {step === 'federated' && <div className={'dimmed'}>
                <img src={eccLoading} alt={'Page is loading'} />
                <div className={'signin-provider-label'}>Signing you in with {providerName}...</div>
            </div>}

            <div className={'bg-neutral-100 px-20 py-14 border border-b-0 border-l-0 border-r-0'}>
                <>
                    <div className={'mb-4'}>
                        <TextInput label={'Enter your Email'} value={username} onChange={(value) => setUsername(value)} readonly={step !== 'username'} onEnterClick={() => moveToSecondStep()} />
                        <CheckboxInput label={'Remember my Username'} value={rememberUsername} onChange={(val) => setRememberUsername(val)} />
                    </div>
                    {step === 'username' && <div className="d-grid gap-2">
                        <Button disabled={isBusy} onClick={() => moveToSecondStep()}>Continue</Button>
                    </div>}
                </>

                {step === 'password' && <>
                    <div className={'mb-6'}>
                        <PasswordInput label={'Password'} linkText={'Forgot password?'} linkClick={() => onResetPassword(username)} hidden={true} onChange={(value) => setPassword(value)} onEnterClick={() => doLogin()} />
                        {authState === 'sms_mfa' && <label>An SMS code has been sent to {authResult?.challengeParam?.CODE_DELIVERY_DESTINATION}</label>}
                        {authState === 'sms_mfa' && <TextInput label={'MFA Code'} linkText={'Forgot username?'} value={mfaCode} onChange={(value) => setMfaCode(value)} />}

                        {authState === 'logging_in' && <label>Please Wait</label>}
                        {authState === 'logged_in' && <label>Log In Successful!</label>}
                    </div>
                    <div className="d-grid gap-2"><Button disabled={isBusy} onClick={doLogin}>Sign In</Button></div>
                </>}
            </div>

            <div className={'my-6 px-20'}>
                You're signing in to {brandingName}. This website is subject to <Link to={'../terms_of_use'}>terms of use.</Link>
            </div>

            <div className={'my-6 px-20'}>
                <hr className={'mb-5'} />
                <span className={'font-semibold text-l'}>What is {brandingName}?</span>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>

            <div className={'my-6 px-20'}>
                <Link to={'../create_account'}>Create a my {brandingName} account {'>'}</Link>
            </div>

            {showExpiredModal && <ExpiredModal callback={handleClose} showModal={showExpiredModal} />}

        </div>
    </div>
}


export default SignIn;

