import React, { useState } from 'react';
import _uniqueId from 'lodash/uniqueId';

function TextInput({ label, value, linkClick, linkText, onChange, hidden, onEnterClick, readonly, maxLength = 100 }) {
    const [id] = useState(_uniqueId('input-'));

    let inputType = 'email';

    if (hidden) {
        inputType = 'password';
    }

    const handleKeyUp = (event) => {
        if (event.keyCode === 13 && typeof (onEnterClick) === 'function') {
            onEnterClick();
        }
    }

    const handleLinkClick = () => {
        if (typeof (linkClick) === 'function') {
            linkClick();
        }
    }

    const handleOnChange = (event) => {
        if (typeof (onChange) === 'function') {
            onChange(event.target.value);
        }
    }

    return <div>
        <div className="flex justify-between">
            <label htmlFor={id} className="block text-base font-semibold leading-6 ecc-text">
                {label}
            </label>
            {linkText && <span className="text-sm leading-6 text-gray-500" id={`${id}-optional`} onClick={handleLinkClick}>
                {linkText}
            </span>}
        </div>
        <div className="mt-2">
            <input
                maxLength={maxLength}
                type={inputType}
                readOnly={readonly}
                name={id}
                id={id}
                className="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-600 placeholder:text-gray-400 text-base"
                aria-describedby={`${id}-optional`}
                value={value}
                onChange={handleOnChange}
                onKeyUp={handleKeyUp}
            />
        </div>
    </div>
}

export default TextInput;