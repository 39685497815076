export default async function reissueInviteCode(signupCode) {
    let fetchUrl = ``

    if (window.location.hostname.toString() === 'localhost') {
        fetchUrl = `https://poc-auth.eccnow.com.au`;
    }
    else {
        fetchUrl = `https://${window.location.hostname.toString()}`;
    }


    fetchUrl += `/invitations/${signupCode}/renew`

    const fetchOptions = {
        method: 'POST'
    }


    return new Promise((resolve) => {
        fetch(fetchUrl, fetchOptions)
            .then((response) => response.json())
            .then((jsonResponse) => resolve(jsonResponse))
            .catch((ex) => ({ error: true, message: ex.message }))
    });
}

