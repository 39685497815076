import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TextInput from '@/components/TextInput';

import zxcvbn from 'zxcvbn';
import ErrorContainer from '@/components/ErrorContainer';

import StepSubTitle from './StepSubTitle';
import StepTitle from './StepTitle';
import Button from '@/components/Button';


function StepPassword({ onNext }) {
    const nav = useNavigate();

    const [password, setPassword] = useState('');
    const [passwordSecond, setPasswordSecond] = useState('');
    const [hasError, setHasError] = useState(false);
    const [error, setError] = useState('');

    const [passwordComplexity, setPasswordComplexity] = useState(0);
    const [passwordFeedback, setPasswordFeedback] = useState("");

    useEffect(() => {
        const passwordResult = zxcvbn(password);
        // console.log('ZXCVBN:', passwordResult);

        setPasswordComplexity(passwordResult.score);

        if (password !== passwordSecond) {
            setHasError(true);
            setError('Passwords do not match');
            return;
        }

        if (passwordResult.score < 2 || password.length < 8 || !/(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/gm.test(password)) {
            setHasError(true);
            setError('Password does not meet complexity requirements');
            setPasswordFeedback(passwordResult.feedback?.warning);
            return;
        }

        if (passwordResult.score < 2) {
            setHasError(true);
            setError('Password does not meet complexity requirements');
            setPasswordFeedback(passwordResult.feedback?.warning);
            return;

        }

        setPasswordFeedback('');
        setHasError(false);
        setError('');

    }, [password, passwordSecond]);

    const handleNext = () => {
        if (!checkAllowNext()) {
            return;
        }

        onNext(password);
    }

    const handleBack = () => {
        nav(-1);
    }

    const checkAllowNext = () => {
        return !hasError;
    }

    return <div>
        <StepTitle>Step 5 of 5 Password</StepTitle>
        <div className={'text-block'}>
            <StepSubTitle>Choose a password</StepSubTitle>
            <p>
                Your password must:
                <ul>
                    <li>have at least 8 characters</li>
                    <li>include at least 1 upper case letter</li>
                    <li>include at least 1 lower case letter</li>
                    <li>include at least 1 number</li>
                    <li>include at least 1 special character</li>
                    <li>cannot contain common or repeated words or patterns</li>
                </ul>
            </p>
            <div className={'my-6'}>
                <TextInput label={'Password'} hidden={true} onChange={(val) => setPassword(val)} />
            </div>
            {hasError && <ErrorContainer message={`${error}. ${passwordFeedback}`} />}
            <TextInput label={'Re-enter Password'} hidden={true} onChange={(val) => setPasswordSecond(val)} />

        </div>

        <Button type={'secondary'} onClick={handleBack}>Back</Button>
        <Button onClick={handleNext}>Next</Button>
    </div>
}

export default StepPassword;