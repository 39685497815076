import React, { useState } from 'react';
import _uniqueId from 'lodash/uniqueId';

function SelectInput({ label, value, linkClick, linkText, onChange, options }) {
    const [id] = useState(_uniqueId('input-'));

    return <div>
        <label htmlFor={id} className="block text-base font-semibold leading-6 ecc-text">
            {label}
        </label>
        <select
            id={id}
            name="location"
            className="mt-2 block w-full rounded-md border-0 py-3  pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-600 placeholder:text-gray-400 text-base"
            value={value} onChange={(event) => onChange(event.target.value)}
        >
            <option>--- Please Select ---</option>
            {options.map(op => <option>{op}</option>)}
        </select>
    </div>

}

export default SelectInput;