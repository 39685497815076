import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import success from '@/assets/success.svg';
import Button from './components/Button';

function ProfileCreated() {
    const nav = useNavigate();

    const handleButtonClick = () => {
        nav('../signin')
    }

    return <>
        <div>
            <img className={'mx-auto h-20 w-20 mt-12 mb-2'} src={success} />

            <div className={'my-10'}>
                <div className={'ecc-success-text text-xl'}>Account Created!</div>
                <div className={'ecc-text text-base'}>Your account has been created successfuly on FoodTrader.. You will need to confirm your email address prior to logging in. </div>
            </div>
        </div>
        <Button onClick={handleButtonClick}>Return to Login</Button>
    </>
}


export default ProfileCreated;