import Button from '@/components/Button';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import StepSubTitle from './StepSubTitle';
import StepTitle from './StepTitle';

function StepEmailCode({ onNext }) {
    const nav = useNavigate();

    const checkAllowNext = () => {
        return true;
    }
    const handleNext = () => {
        if (!checkAllowNext()) {
            return;
        }

        onNext();
    }

    const handleBack = () => {
        nav(-1);
    }

    return <div>
        <StepTitle>Step 1 of 5 Terms of Use</StepTitle>
        <div className={'my-4'}>
            <StepSubTitle>Terms of Use</StepSubTitle>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation</p>

            <p>By clicking ‘I Agree'. this means you have read and understood the full terms of use and agree to comly with them.</p>

        </div>

        <Button type={'secondary'} onClick={handleBack}>Back</Button>
        <Button onClick={handleNext}>Next</Button>
    </div>
}

export default StepEmailCode;