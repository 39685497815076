import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';

import PhoneInput from '@/components/PhoneInput';
import ErrorContainer from '@/components/ErrorContainer';

import StepSubTitle from './StepSubTitle';
import StepTitle from './StepTitle';
import Button from '@/components/Button';


function StepFour({ onNext }) {
    const [mobile, setMobile] = useState('');
    const [hasError, setHasError] = useState(false);

    const nav = useNavigate();

    useEffect(() => {
        setHasError(!checkAllowNext());
    }, [mobile])

    const handleNext = () => {
        if (!checkAllowNext()) {
            return;
        }

        onNext(mobile);
    }

    const checkAllowNext = () => {
        if (!mobile || !mobile.phoneNumber) {
            return false;
        }

        return validator.isMobilePhone(mobile.phoneNumber, 'en-AU')
    }

    const handleBack = () => {
        nav(-1);
    }


    return <div>
        <StepTitle>Step 4 of 5 Mobile</StepTitle>
        <div className={'text-block'}>
            <StepSubTitle>Enter your Mobile</StepSubTitle>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation
            </p>
            <PhoneInput label={'Mobile Number'} onChange={(val) => setMobile(val)} />
            {hasError && <ErrorContainer message={'Please enter a valid mobile phone number'} />}
        </div>

        <Button type={'secondary'} onClick={handleBack}>Back</Button>
        <Button disabled={!checkAllowNext()} onClick={handleNext}>Next</Button>
    </div>
}

export default StepFour;