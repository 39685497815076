import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TextInput from '@/components/TextInput';
import ErrorContainer from '@/components/ErrorContainer';
import StepSubTitle from './StepSubTitle';
import StepTitle from './StepTitle';
import Button from '@/components/Button';

function StepName({ onNext }) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const nav = useNavigate();

    useEffect(() => {
        setHasError(false);
        setErrorMessage('');

        if (!checkAllowNext()) {
            setHasError(true);
            setErrorMessage('Your first name and last name are required.')
        }
    }, [firstName, lastName])

    const handleNext = async () => {
        if (!checkAllowNext()) {
            return;
        }

        return onNext(firstName, lastName);
    }

    const checkAllowNext = () => {
        return firstName !== '' && lastName !== '';
    }

    const handleBack = () => {
        nav(-1);
    }

    return <div>
        <StepTitle>Step 2 of 5 Email</StepTitle>
        <div className={'my-4'}>
            <StepSubTitle>Enter your name</StepSubTitle>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation
            </p>
            <div className={'my-6'}>
                <TextInput label={'First Name'} onChange={(val) => setFirstName(val)} />
            </div>

            <TextInput label={'Last Name'} onChange={(val) => setLastName(val)} />

            {hasError && <ErrorContainer message={errorMessage} />}
        </div>

        <Button type={'secondary'} onClick={handleBack}>Back</Button>
        <Button disabled={!checkAllowNext()} onClick={handleNext}>Next</Button>
    </div>
}

export default StepName