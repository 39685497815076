
import { useEffect, useState } from 'react';
import { getUrlParams } from '@/utils/getUrlParams';
import { Auth } from 'aws-amplify';
import { eraseCookie } from '@/utils/cookieHelper';
import eccLoading from './assets/ecc-loading.gif';
import success from './assets/success.svg';

function Logout() {
    const [loggedOut, setLoggedOut] = useState(false);

    useEffect(() => {
        const { post_logout_redirect_uri } = getUrlParams();

        eraseCookie("id_token");
        eraseCookie("access_token");
        eraseCookie("refresh_token");
        localStorage.removeItem('client-id');

        Auth.signOut().then(() => {
            if (post_logout_redirect_uri) {
                window.location = post_logout_redirect_uri;
            }
            else {
                setLoggedOut(true);
            }
        });
    }, []);

    if (loggedOut) {
        return <div>
            <div className={'complete-container'}>
                <div className={'complete-container-success'}>
                    <img src={success} />
                </div>
                <div className={'complete-container-text'}>You have sucessfully logged out</div>
                <div className={'complete-container-subtext'}>You can now close this window.</div>
            </div>
            <div className={'block-button-container'}>
                <button type={'button'} onClick={() => window.close()} className={'btn btn-primary btn-block'}>Close</button>
            </div>

        </div>
    }


    return <div className={'signin-parent-container'}>
        <div className={'signin-label'}>Logging out...</div>
        <div className={'overlayImg'}><img src={eccLoading} /></div>
    </div>
}

export default Logout;