import React, { useState, useEffect } from 'react';
import _uniqueId from 'lodash/uniqueId';
import countries from '@/assets/countries';
import countryCodes from '@/assets/countrycodes';

function findCountryByCode(code) {
    let foundCountry = '';

    Object.keys(countryCodes).forEach(key => {
        if (countryCodes[key] === code) {
            foundCountry = key;
        }
    });

    return foundCountry;
}

function PhoneInput({ label, value, linkClick, linkText, onChange, hidden, onEnterClick }) {
    const [id] = useState(_uniqueId('input-'));
    const [internalValue, setInternalValue] = useState();
    const [currentCountry, setCurrentCountry] = useState('AU');
    const [countryList] = useState(Object.keys(countries).map(k => k).sort((a, b) => a - b));

    const handleKeyUp = (event) => {
        if (event.keyCode === 13 && typeof (onEnterClick) === 'function') {
            onEnterClick();
        }
    }

    const handleOnChange = (textValue) => {
        onChange({ countryCode: currentCountry, phoneNumber: `+${countryCodes[currentCountry]}${textValue}` })
    }

    return <div>
        <label htmlFor="phone-number" className="block text-base font-semibold ecc-text leading-6 text-gray-900">
            {label}
        </label>
        <div className="relative mt-2 rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 flex items-center">
                <label htmlFor="country" className="sr-only">
                    Country
                </label>
                <select
                    id="country"
                    name="country"
                    autoComplete="country"
                    className="h-full rounded-md border-0 bg-transparent py-0 pl-3 pr-3 text-gray-500 focus:outline-none text-base"
                    value={currentCountry} onChange={(e) => setCurrentCountry(e.target.value)}
                >
                    {countryList.map(key => <option>{key}</option>)}
                </select>
            </div>

            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-20">
                <span className="text-gray-500 text-base">(+{countryCodes[currentCountry]})</span>
            </div>

            <input
                type="text"
                name="phone-number"
                id="phone-number"
                className="block w-full rounded-md border-0 py-3 pl-36 text-gray-900 ring-1 ring-inset ring-gray-600 placeholder:text-gray-400 placeholder:text-gray-400  text-base"
                placeholder=""
                onChange={(e) => handleOnChange(e.target.value)} onKeyUp={handleKeyUp}
            />
        </div>
    </div>
}

export default PhoneInput;