import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        authState: 'none',
        preferredMFA: 'none',
        currentMFAPrompt: undefined,
        currentUser: undefined,
        currentSession: undefined,
        error: false,
        errorSource: undefined,
        errorMessage: undefined,
        brandingName: 'Default Branding',
        brandingLogoUrl: undefined
    },
    reducers: {
        setAuthState: (state, action) => {
            state.authState = action.payload;
        },
        setPreferredMFA: (state, action) => {
            state.preferredMFA = action.payload;
        },
        setProfileComplete: (state, action) => {
            state.profileComplete = action.payload;
        },
        setCurrentMFAPrompt: (state, action) => {
            state.currentMFAPrompt = action.payload;
        },
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload;
        },
        setCurrentSession: (state, action) => {
            state.currentSession = action.payload;
        },

        setError: (state, action) => {
            state.error = action.payload;
        },
        setErrorSource: (state, action) => {
            state.errorSource = action.payload;
        },
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        },
        setBrandingName: (state, action) => {
            state.brandingName = action.payload;
        },
        setBrandingLogoUrl: (state, action) => {
            state.brandingLogoUrl = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setAuthState, setPreferredMFA, setCurrentMFAPrompt, setCurrentUser, setCurrentSession, setProfileComplete, setError, setErrorMessage, setErrorSource, setBrandingName, setBrandingLogoUrl } = authSlice.actions

export const selectAuthState = (state) => state.auth.authState;
export const selectPreferredMFA = (state) => state.auth.preferredMFA;
export const selectCurrentMFAPromt = (state) => state.auth.currentMFAPrompt;
export const selectProfileComplete = (state) => state.auth.profileComplete;

export const selectCurrentUser = (state) => state.auth.currentUser;
export const selectCurrentSession = (state) => state.auth.currentSession;

export const selectError = (state) => state.auth.error;
export const selectErrorSource = (state) => state.auth.errorSource;
export const selectErrorMessage = (state) => state.auth.errorMessage;

export const selectBrandingName = (state) => state.auth.brandingName;
export const selectBrandingLogoUrl = (state) => state.auth.brandingLogoUrl;

export default authSlice.reducer