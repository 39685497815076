import { useState, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux'

import '@/App.css';
import SignIn from '@/features/SignIn/SignIn';
import CreateAccount from '@/features/CreateAccount'
import { Auth } from 'aws-amplify';
import ResetPassword from '@/features/ResetPassword';
import TermsOfUse from '@/features/TermsOfUse/TermsOfUse';
import MFASignup from '@/MFASignup';
import EditProfile from '@/features/EditProfile';
import AuthStateChangeHandler from '@/AuthStateChangeHandler';
import SignUpProfile from '@/features/InviteSignUp/SignUpProfile';
import ProfileCreated from '@/ProfileCreated';
import { setAuthState, setProfileComplete, setCurrentMFAPrompt, setError, setErrorSource, setErrorMessage,setBrandingName, setBrandingLogoUrl, selectBrandingName, selectBrandingLogoUrl } from '@/stores/authSlice';
import ErrorPage from '@/ErrorPage';
import Logout from '@/Logout';
import ProfileConfirmed from '@/ProfileConfirmed';
import FoodTraderLogo from '@/assets/foodtrader-logo.svg'
import eccLoading from '@/assets/ecc-loading.gif';
import ComingSoon from '@/features/ComingSoon';
import BottomNav from './BottomNav';
import getDynamicContent from '@/utils/getDynamicContent';



function App() {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const nav = useNavigate();

  const brandingName = useSelector(selectBrandingName);
  const brandingLogoUrl = useSelector(selectBrandingLogoUrl);

  useEffect(() => {
    if (window.location.pathname === '/email_confirmed') {
      return nav('email_confirmed');
    }

    if (window.location.pathname === '/signup') {
      return nav('signup');
    }

    if (window.location.pathname === '/logout') {
      return nav('logout');
    }

    if (window.location.pathname.startsWith('/profile')) {
      return nav(window.location.pathname);
    }


    if (window.location.pathname === '/no-redirect') {
      dispatch(setError(true));
      dispatch(setErrorSource('client_error'));
      dispatch(setErrorMessage('No redirect configured'))
      return nav('error')
    }

    const currentUrlParams = new URLSearchParams(window.location.search.toString());
    console.log(currentUrlParams.has('error'))
    if (currentUrlParams.has('error')) {
      dispatch(setError(true));
      dispatch(setErrorSource(currentUrlParams.get('error')));
      dispatch(setErrorMessage(currentUrlParams.get('error_description')))
      return nav('error')
    }

    setIsLoading(true);

    Auth.currentAuthenticatedUser()
      .then((user) => {
        dispatch(setCurrentMFAPrompt(user.attributes['custom:mfa_prompt']));
        dispatch(setProfileComplete(user.attributes['custom:profile_complete']))
        dispatch(setAuthState('logged_in'))
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        nav('signin');
      });
  }, []);

  useEffect(() => {
    //setIsLoading(true);
    getDynamicContent('branding.json').then((pageContent) => {
      const brandingData = JSON.parse(pageContent);
      dispatch(setBrandingName(brandingData.product_name));
      dispatch(setBrandingLogoUrl(brandingData.product_logo_url));
    })
  }, []);



  return (
    <div className="App">
      <AuthStateChangeHandler />
      <nav className="navbar fixed-top navbar-light bg-light app-header-bar">
        <img src={brandingLogoUrl} className={'h-8 mt-2'} />
      </nav>
      <div className={'app-header-bar-nav-container'}>
        <a href={''} onClick={() => nav(-1)} >{'<'} Back</a>
      </div>

      <div className={'app-root'}>
        {isLoading && <div className={'overlayImg'}><img src={eccLoading} /></div>}

        <div className={"mx-auto max-w-7xl sm:px-6 w-full"}>
          <Routes>
            <Route path={'/'} element={<label>Loading...</label>} />
            <Route path={'/create_account/*'} element={<CreateAccount />} />
            <Route path={'/signin/*'} element={<SignIn onResetPassword={() => nav('reset_password')} />} />
            <Route path={'/reset_password/*'} element={<ResetPassword />} />

            <Route path={'/terms_of_use/*'} element={<TermsOfUse />} />
            <Route path={'/mfa_signup/*'} element={<MFASignup />} />
            <Route path={'/profile/*'} element={<EditProfile />} />
            <Route path={'/complete_profile/*'} element={<EditProfile from={'complete_profile'} />} />
            <Route path={'/signup/*'} element={<SignUpProfile />} />
            <Route path={'/complete'} element={<ProfileCreated />} />
            <Route path={'/email_confirmed'} element={<ProfileConfirmed />} />

            <Route path={'/logout'} element={<Logout />} />

            {/* Error Handling */}
            <Route path={'/error'} element={<ErrorPage />} />
          </Routes>
        </div>
      </div>

      <BottomNav />

    </div>
  );
}

export default App;
